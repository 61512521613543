.header {
  background: #7371fc;
  position: relative;
  display: table;
  width: 100%;
  min-height: 55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  text-align: center;
  z-index: 100;
}

.plusIconContainer {
  float: right;
  margin-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  top: 50%;
}

.plusIcon {
  color: #fff;
  -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @-webkit-keyframes rotate-in-center {
    0% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-center {
    0% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
  }
}

.plusIcon:active {
  -webkit-animation: rotate-out-center 0.6s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: rotate-out-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @-webkit-keyframes rotate-out-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
  }
  @keyframes rotate-out-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
  }
}

.checkIcon {
  color: #16db65;
  -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: rotate-in-center 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @-webkit-keyframes rotate-in-center {
    0% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-center {
    0% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
  }
}

.brandLogo {
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  float: left;
  margin-left: 10px;
  top: 50%;
  cursor:pointer;
  margin-top: 7px;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
}
