.songTitle {
  color: #fff;
  font-weight: 800;
  width: 85%;
}

.songArtist {
  color: #fff;
}

.songList {
  margin: 10px;
  margin-right: 0;
  -webkit-animation: flip-in-hor-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
      transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
      transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
  }
}

hr {
  border-color: transparent;
  border-radius: 10px;
  height: 2px;
  background-color: #7371fc;
  margin: 10px;
}

img {
  height: 45px;
  width: 45px;
  border-radius: 3px;
  margin-right: 10px;
  margin-top: -3px;
  float: right;
}
